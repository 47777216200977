<template>
    <b-modal id="modalTimetable" :title="$t(`Horario Punto de Retiro ID ${this.form.id}`)" modal-class="custom-dialog dialog-800"
    ok-only no-close-on-esc no-close-on-backdrop centered hide-footer :ok-disabled="loading">
      <form-render v-show="!loading" :form.sync="form" :fields="fields" containerButtonsClass="col-sm-12" class="px-0" ref="formRenderStatus">
      </form-render>
      <div class="col-sm-12 mt-4">
      <table-render 
      :key="keyTableRender"
      :schema="schema" 
      :rows.sync="rows"
      :stickyHeader="`calc(100vh - 2.5rem)`"
      id="timetable"
      :tableClass="'with-scroll'"
    >
    </table-render>
    </div>
      <div v-show="loading">
        <b-skeleton width="30%"/>
        <b-skeleton type="input"/>
        <b-skeleton width="30%" class="mt-1"/>
        <b-skeleton type="input"/>
      </div>
    </b-modal>
  </template>
  
<script>
export default {
  name:'modalTimetable',
  props: ['timetable'],
  data () {
    return {
      form: {},
      fields: [],
      loading: true,
      keyFormRender: 0,
      keyTableRender: 0,
      schema: [],
      rows: []

    }
  },
  watch: {
    timetable () {
      this.form = {
        ...this.timetable
      }
      this.rows = this.form.schedules.map(item => {
        const formatterDay = item.days.map(day => {
          return day.substr(0, 2).toUpperCase()
        })
        return {
          ...item,
          days: formatterDay.toString().replace(/,/g, '-')
        }
      })
      this.setInitialData()
      this.loading = false
      this.keyFormRender++
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.schema = [
        {label: 'Días', sortable: true, key: 'days'},
        {label: 'Apertura', sortable: true, key: 'open'},
        {label: 'Cierre', sortable: true, key: 'close'}
      ]
      this.fields = [ 
        {fieldType: 'FieldInput', name: 'name', label: 'Nombre', containerClass: 'col-sm-12 personalize-form-name', disabled: true}
      ]

    }
  }
 
}
</script>

<style>
.modal-body{
    padding: 2.8rem 4.4rem !important;
}
.personalize-form-name input {
  background: none;
  border: none;
  color: #AEAEAE;
  background-color: white !important;
}
</style>