<template>
    <b-modal id="modalStatusDelivery" :title="$t('Cambiar de estado')" modal-class="custom-dialog dialog-600" :ok-title="$t('Actualizar')" ok-variant="warning" 
  ok-only no-close-on-esc no-close-on-backdrop centered @close="close" @ok="ok" :ok-disabled="loading || disabledForm">
      <form-render v-show="!loading" :disableAll="disabledForm" :form.sync="form" :key="keyFormRender" :fields="fields" containerButtonsClass="col-sm-12" ref="formRenderStatus" @send="onAccept">
      </form-render>
      <div v-show="loading">
        <b-skeleton width="30%"/>
        <b-skeleton type="input"/>
        <b-skeleton width="30%" class="mt-1"/>
        <b-skeleton type="input"/>
      </div>
    </b-modal>
</template>

<script>
export default {
  name:'modalStatus',
  props: ['delivery', 'multipleDelivery'],
  data () {
    return {
      form: {},
      fields: [],
      loading: true,
      keyFormRender: 0,
      disabledForm: false
    }
  },
  watch: {
    delivery () {
      this.disabledForm = this.delivery.disabled_checkbox
      this.form = {
        ...this.delivery,
        status_new: {id: 10, text: 'Entregado'}
      }
      this.fields.filter(el => !!el.hideInput).map(field => {
        field.containerClass = this.multipleDelivery ? 'hide' : 'col-md-4 container-info personalize-form-enabled'
      })
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [ 
        {fieldType: 'FieldInput', name: 'imported_id', label: 'N° Seguimiento', containerClass: 'col-md-4 container-info personalize-form-enabled', disabled: true, hideInput: true},
        {fieldType: 'FieldInput', name: 'tracking_number', label: 'OT', containerClass: 'col-md-4 container-info personalize-form-enabled', disabled: true, hideInput: true},
        {fieldType: 'FieldSelect', name: 'status_new', label: 'Nuevo Estado', containerClass: 'col-md-12 container-info personalize-form-enabled', disabled: true, clearable: false},
        {fieldType: 'FieldTextarea', name: 'comment', label: 'Comentario', containerClass: 'col-md-12 container-info', disabled: false}
      ]
      this.loading = false

    },
    close () {
      this.$emit('update:delivery', {})
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderStatus.checkForm()
    },
    payloadUpdate(data, delivery) {
      return {
        id: delivery.id,
        current_status_id: delivery.status.id,
        new_status_id: data.status_new.id,
        comment: data.comment 
      }
    },
    onAccept (data) {
      this.loading = true
      let payload = null
      if (this.multipleDelivery) {
        payload = this.delivery.map(delivery => {
          return this.payloadUpdate(data, delivery)
        })
      } else {
        payload = this.payloadUpdate(data, this.delivery)
      }
      this.$emit('send', { payload, multiple: !!this.multipleDelivery})
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    }
  }
 
}
</script>
<style>
.personalize-form-enabled input, .personalize-form-enabled .vs__dropdown-toggle {
  background: none;
  border: none;
  color: #AEAEAE;
  background-color: white !important;
}
.personalize-form-enabled .vs__selected {
  color: #AEAEAE !important;
}
</style>