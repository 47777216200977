<template>
    <b-modal id="modalMaps" :title="$t(`Dirección Punto Retiro ID ${this.form.id}`)" modal-class="custom-dialog dialog-800"
    ok-only no-close-on-esc no-close-on-backdrop centered hide-footer :ok-disabled="loading">
      <form-render v-show="!loading" :form.sync="form" :fields="fields" containerButtonsClass="col-sm-12" ref="formRenderStatus">
      </form-render>
      <div class="row mt-2" v-if="!loading">
        <div class="col-sm-12">
          <google-map id="map" :infoMap="infoMap"/>
        </div>
      </div>
      <div v-show="loading">
        <b-skeleton width="30%"/>
        <b-skeleton type="input"/>
        <b-skeleton width="30%" class="mt-1"/>
        <b-skeleton type="input"/>
      </div>
    </b-modal>
  </template>
  
<script>
export default {
  name:'modalMaps',
  props: ['pudo', 'countries'],
  data () {
    return {
      form: {},
      fields: [],
      loading: true,
      keyFormRender: 0,
      infoMap: null

    }
  },
  watch: {
    pudo () {
      const countryFilter = this.pudo.address ? this.countries.filter(el => this.pudo.address.country.code.includes(el.code)) : null
      this.form = {
        ...this.pudo,
        country_name: countryFilter[0].name,
        full_address: this.pudo.address.full_address
      }
      this.setInitialData()
      this.infoMap = {latitude: this.form.address.location.latitude, longitude: this.form.address.location.longitude, title: this.form.full_address}
      this.loading = false
      this.keyFormRender++
    },
    countries () {
      const index = this.fields.findIndex(el => el.name === 'country_name')
      if (index !== -1) this.fields[index].options = this.countries
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [ 
        {fieldType: 'FieldInput', name: 'country_name', label: 'País', containerClass: 'col-sm-12 personalize-form-map', disabled: true},
        {fieldType: 'FieldInput', name: 'full_address', label: 'Dirección', containerClass: 'col-sm-12 personalize-form-map', disabled: true, iconLeft: {icon: 'MapPinIcon', size: '20', stroke: 1.5, color: '#EA4335'}}
      ]

    }
  }
 
}
</script>

<style>
.modal-body{
    padding: 2.8rem 4.4rem !important;
}
.content-img {
    margin-top: 1rem;
}
.content-img img {
    width: 100%;
}
#map {
  border-radius: 22px;
  overflow: hidden;
}
.personalize-form-map input {
  background: none;
  border: none;
  color: #AEAEAE;
  background-color: white !important;
}
#full_address .input-group-text {
  border: none;
  padding: 0rem 0rem 0rem 1rem;
}
</style>