<template>
      <b-card>
        <b-tabs v-model="tabIndex" align="left" class="tab-primary" v-if="!isPudo && !loading">
          <b-tab>
            <template #title>
              <div class="tab-title">
                <span> {{$t('Listado')}} </span>
              </div>
            </template>
            <list></list>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <div>
                <span> {{$t('Envíos')}} </span>
              </div>
            </template>
            <delivery></delivery>
          </b-tab>
        </b-tabs>
        <div v-if="isPudo && !loading">
          <delivery></delivery>
        </div>
        <div v-if="loading">
          <div class="center-spinner">
              <b-spinner />
          </div>
        </div>
      </b-card>
</template>
<script>
import list from './list-pudos/List.vue'
import delivery from './delivery-pudos/Delivery.vue'
import { mapGetters } from 'vuex'
export default {
  components: { list, delivery },
  data () {
    return {
      tabIndex: 0,
      isPudo: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData() {
      this.sessionUser = this.$session.get('cas_user')
      this.isPudo = ['pudo'].includes(this.sessionUser.role)
      this.loading = false
    }
  }
}
</script>
<style>
.center-spinner {
    display: flex;
    justify-content: center;
}
</style>