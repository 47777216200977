<template>
    <div>
        <label for="">Fecha</label>
        <p class="customize-date">{{ currentDate }}</p>
        <div class="container-form">
          <div>
            <form-render
                ref="formRenderPudo"
                :form.sync="formPudo"
                :fields="fieldsPudo"
                :key="keyFormRenderPudo"
                class="margin-forms"
                @send="handleSubmitPudo"
            />
          </div>
          <div>
            <form-render
                ref="formRenderPudoCountry"
                :form.sync="formPudo"
                :fields="fieldsPudoCountry"
                :key="keyFormRenderPudoCountry">
            </form-render>
          </div>
        </div>
      <div class="col-md-12 d-flex align-items-center justify-content-end">
        <b-button class="padding-button"  variant="warning" @click="validateForm" :disabled="loading.createPudo">
          <b-spinner v-if="loading.createPudo" small></b-spinner>
          <span v-else>Crear</span>
        </b-button>
      </div>  
    </div>
  </template>

<script>
import BaseServices from '@/store/services/index'
import { mapGetters } from 'vuex'
export default {
  name: 'creation-individual-pudo',
  props: ['pudo', 'closeModal', 'carriers'],
  data() {
    return { 
      formPudo : {
        emails: []
      },
      fieldsPudo: [], 
      fieldsPudoCountry: [],     
      keyFormRenderPudo: 0,
      keyFormRenderPudoCountry: 0,
      currentDate: null,
      nextLevelData: {},
      baseService: new BaseServices(this),
      loading: {
        createPudo: false
      },
      internalLevels: {
        cl: {
          required: [3],
          display: [3]
        },
        co: {
          required: [1, 2],
          display: [1, 2]
        },
        pe: {
          required: [1, 2, 3],
          display: [2, 3]
        },
        ar: {
          required: [1, 2, 3],
          display: [1, 2, 3]
        },
        mx: {
          required: [1, 2, 3, 4],
          display: [3, 4]
        },
        ec: {
          required: [1, 2, 3],
          display: [1, 2, 3]
        }
      }
    }
  },
  watch: {
    countries () { 
      this.setCountries()
    },
    organizations () {
      this.setOrganizations()
    },
    shippersByOrganization () {
      this.setSelectOptions('shipper_id', { options: this.shippersByOrganization }, this.fieldsPudo)
    },
    carriers () {
      this.setSelectOptions('courier', { options: this.carriers }, this.fieldsPudo)
    },
    formPudo: {
      handler (val) {
        const fieldEmails = this.fieldsPudo[this.getFieldIndex(this.fieldsPudo, 'emails')]
        if (fieldEmails) fieldEmails.containerClass = this.formPudo.organization_id ? 'col-sm-12 container-info' : 'hide'
      }, deep: true
    }
  },
  computed: {
    ...mapGetters({
      levels: 'getLevels',
      countries: 'getCountries',
      shippersByOrganization: 'getShippersByOrganizationSimplifiedQueryParams',
      organizations: 'getOrganizationsGlobal',
      createPudo: 'postPudo'
    })
  },
  mounted() { 
    this.currentDate = new Date().toLocaleDateString()
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fieldsPudo = [
        {fieldType: 'FieldInput', name: 'code', label: 'Código Punto de retiro', containerClass: 'col-sm-12 container-info', placeholder: 'Ingrese Código Punto de retiro', validation: 'required'},  
        {fieldType: 'FieldInput', name: 'name', label: 'Nombre', containerClass: 'col-sm-12 container-info', placeholder: 'Ingrese nombre', validation: 'required'},
        {fieldType: 'FieldInput', name: 'deadline_days', type:'number', label: 'Plazo para retiro', containerClass: 'col-sm-12 container-info', placeholder: 'Ingrese días hábiles', max: 3, validation: 'required'}
      ]
      this.fieldsPudoCountry = [
        {fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-sm-12 container-info', change: this.changeCountry, validation: 'required', addFlags: true, disabled: true},
        {fieldType: 'FieldInput', name: 'state_mx', disabled: true, label: 'Estado', containerClass: 'hide'},
        {fieldType: 'FieldInput', name: 'municipality_mx', disabled: true, label: 'Municipio', containerClass: 'hide'},
        {fieldType: 'FieldAutocomplete', name: 'address', label: 'Dirección', containerClass: 'col-sm-12 container-info', validation: 'required', placeholder: 'Ingrese dirección',
          dependency: 'country',
          searchOnType: { fx: this.searchGoogleAddress, nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true }, clearable: true},
        {fieldType: 'FieldInput', name: 'information', dependency: 'address', label: 'Información adicional', containerClass: 'col-sm-12 container-info', placeholder: 'Ingrese información adicional'}
      ]
      this.sessionUser = this.$session.get('cas_user')
      this.isAdmin = ['admin', 'superadmin'].includes(this.sessionUser.role)
      this.typePudoFieldChange()
      this.setOrganizations()
      this.setCountries()
    },
    setOrganizations() {
      if (this.organizations && !!this.organizations.length) {
        this.formattedOrganization = this.organizations.map(el => {
          if (el.organization_type.id === 1) {
            return {...el, text: `${el.name} (M)`}
          } else {
            return {...el, text: el.name}
          }
        })
        this.setSelectOptions('organization_id', { options: this.formattedOrganization }, this.fieldsPudo)
      }
    },
    setCountries() {
      if (this.countries && !!this.countries.length) {
        this.formattedCountry = this.countries.filter(item => {
          return item.code.toLowerCase() !== 'ar' && item.code.toLowerCase() !== 'ec'
        })
        this.setSelectOptions('country', { options: this.formattedCountry }, this.fieldsPudoCountry)
      }
    },
    typePudoFieldChange () {
      const typePudo = this.pudo.type?.id
      if (typePudo === 1) {
        this.fieldsPudo.splice(4, 0, { fieldType: 'FieldSelect', label: 'Courier', useLabel: true, name: 'courier', options: this.carriers, containerClass: 'col-md-12 container-info', placeholder: 'Seleccione Courier', validation: 'required', change: this.changeCourier, disabled: false})
      }
      if (typePudo === 2) {
        this.fieldsPudo.splice(4, 0, 
          {fieldType: 'FieldSelect', name: 'organization_id', label: 'Empresa', useLabel: true, change: this.changeOrganization, containerClass: 'col-sm-12  container-info', validation: 'required'}, 
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', dependency: 'organization_id', containerClass: 'hide', useSkeleton: false, disabled: false},
          {fieldType: 'FieldTags', name: 'emails', label: 'Empleado', containerClass: 'hide', placeholder: 'Agregar correo empleado', validation: 'required', newTagsDesign: true,
            icon: {type: 'MailIcon'},
            tagsOption: {
              validateEmail: true,
              duplicateTagText: 'Correo duplicado no se puede volver a agregar',
              customTag: {background: '#F5D098', color: '#1D192B', colorBtnClose: '#1D192B'}
            }
          }) 
      }
      if ([3, 4].includes(typePudo)) {
        const isMarketplace = ['marketplace'].includes(this.sessionUser.role)
        const isTypePudoSeller = typePudo === 4
        const indexCountry = this.fieldsPudoCountry.findIndex(el => el.name === 'country')
        // Ocultar país
        this.fieldsPudoCountry[indexCountry].containerClass = 'hide'
        this.changeCountry('country', this.sessionUser.country)
        // Setear organización del usuario
        this.formPudo.organization_id = this.sessionUser.organization
        // Creamos campos necesarios para la creación
        // Validamos si es isMarketplace para mostrar el campo Empresa sino lo ocultamos
        // Requerimos el campo seller en caso sea isTypePudoSeller
        // Si el usuario es mkp y la creación es de tipo seller mostramos en campo Seller
        this.fieldsPudo.splice(4, 0, 
          {fieldType: 'FieldSelect', name: 'organization_id', label: 'Empresa', useLabel: true, containerClass: isMarketplace ? 'col-sm-12  container-info' : 'hide', validation: 'required', disabled: true},
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', dependency: 'organization_id', validation: isTypePudoSeller ? 'required' : '', containerClass: isTypePudoSeller && isMarketplace ? 'col-sm-12  container-info' : 'hide', useSkeleton: false, disabled: false},
          {fieldType: 'FieldTags', name: 'emails', label: 'Empleado', containerClass: 'hide', placeholder: 'Agregar correo empleado', validation: 'required', newTagsDesign: true,
            icon: {type: 'MailIcon'},
            tagsOption: {
              validateEmail: true,
              duplicateTagText: 'Correo duplicado no se puede volver a agregar',
              customTag: {background: '#F5D098', color: '#1D192B', colorBtnClose: '#1D192B'}
            }
          })
        // Si es tipo seller llamamos la función changeOrganization para cargar sus sellers en el campo Seller
        // Sino seteamos por defecto en de la sesión
        isTypePudoSeller ? this.changeOrganization('organization', this.sessionUser.organization) : this.formPudo.shipper_id = this.sessionUser.shipper
      }
    },
    setSelectOptions (name, { options }, field) {
      const index = field.findIndex(el => el.name === name)
      if (index !== -1) {
        field[index].options = options
        this.keyFormRenderPudo++
      }
    },
    changeOrganization (name, value) {
      const index = this.fieldsPudo.findIndex(el => el.name === 'shipper_id')
      if (!!value?.id) {
        const indexOrg = this.fieldsPudo.findIndex(el => el.name === 'organization_id')
        this.fieldsPudo[indexOrg].disabled = true
        this.fieldsPudo[index].containerClass = value.organization_type.id === 2 ? 'hide' : 'col-sm-12  container-info'
        this.formPudo = {
          ...this.formPudo,
          shipper_id: null
        }
        this.fieldsPudo[index].options = []
        const queryParams = {}
        const params = {
          organization_id: !!this.organizationId ? this.organizationId.id : this.formPudo.organization_id?.id || 0
        }
        this.fieldsPudo[index].useSkeleton = true
        if (value.organization_type.id === 1) {
          this.$store.dispatch('fetchService', { name: 'getShippersByOrganizationSimplifiedQueryParams', queryParams, params, onSuccess: () => this.onSuccessShipper(index, indexOrg, value), onError: () => this.onErrorShipper(index, indexOrg)})
        } else {
          this.formPudo.shipper_id = {id: value.id, text: value.name}
          this.getOrganization(value, indexOrg)
        }
      } else {
        this.fieldsPudo[index].containerClass = 'hide'
        this.formPudo.shipper_id = null
        this.formPudo.organization_id = null
        this.keyFormRenderPudo++
        this.changeCountry('country', null)
        return null
      }   
      
    },
    onSuccessShipper (index, indexOrg, value) {
      this.fieldsPudo[index].useSkeleton = false 
      if (this.isAdmin) this.getOrganization(value, indexOrg)
      else this.fieldsPudo[indexOrg].disabled = true
    },
    onErrorShipper (index, indexOrg) {
      this.fieldsPudo[indexOrg].disabled = false
      this.fieldsPudo[index].useSkeleton = false
    },
    changeCountry (name, value) {
      this.formPudo = {
        ...this.formPudo,
        address: null,
        information: null
      }
      const indexAddress = this.fieldsPudoCountry.findIndex(el => el.name === 'address')
      this.fieldsPudoCountry[indexAddress].dependency = value?.id ? `level${Math.max(...this.internalLevels[value.code.toLowerCase()].required)}_pudo_${value.code.toLowerCase()}` : 'country'
      this.formPudo = {
        ...this.formPudo,
        level1_pudo_co: undefined,
        level2_pudo_co: undefined,
        level2_pudo_pe: undefined,
        level3_pudo_pe: undefined,
        level3_pudo_cl: undefined,
        level3_pudo_mx: undefined,
        level4_pudo_mx: undefined,
        state_mx: '',
        municipality_mx: ''
      }
      this.fieldsPudoCountry = this.fieldsPudoCountry.filter(el => !el.name.includes('level') && !el.name.includes('skeleton') && !['length', 'height', 'width'].includes(el.name))
      this.country = value
      this.formPudo = {
        ...this.formPudo,
        country: value
      }
      this.setLevelsParentMX()
      //limpia todo
      if (!value?.id) return null
      //Guardo los campos normales
      let indexFromCountry = this.fieldsPudoCountry.findIndex(el => el.name === name)

      indexFromCountry++
      const display = this.internalLevels[this.country.code.toLowerCase()].display
      display.map(el => {
        this.fieldsPudoCountry.splice(indexFromCountry, 0, { name: `skeleton${el}_${value.code.toLowerCase()}`, useSkeleton: true, containerClass: 'col-sm-12 col-md-12 container-info' })
      })
      this.fieldsPudoCountry.splice(indexFromCountry, 0)
      
      //Pego los campos originales
      if (value?.id) {
        this.nextLevelData = {}
        if (['mx'].includes(value.code.toLowerCase())) {
          this.nextLevelData.level = 1
        }
        this.getLevels(value, false)
      }
      
    },
    getLevels (country, forceGetLevels) {
      const params = {country: country.code.toLowerCase() }
      const { queryParams, onSuccessFx } = this.getQueryParamLevel(country.code.toLowerCase())
      if (!['mx'].includes(params.country) || forceGetLevels) {
        this.$store.dispatch('fetchService', { name: 'getLevels', params, queryParams, onSuccess: (data1, data2) => onSuccessFx(data1, data2)})
      } else {
        this.$store.commit('setLevels', [])
        this.loadedLevels('getLevels')
      }
    },
    getQueryParamLevel (country_code) {
      let queryParams = {}
      let onSuccessFx = () => this.loadedLevels('getQueryParamLevel')
      if (['co', 'mx', 'pe'].includes(country_code)) {
        queryParams = {...this.nextLevelData}
        delete queryParams.next
        if (this.nextLevelData.next) {
          onSuccessFx = (data) => {
            this.setManualOptions(data.data.level1, this.nextLevelData.next)
          }
        }
      }
      return { queryParams, onSuccessFx }
    },
    searchZipCode (name, value) {
      const countryID = this.formPudo.country.id
      const params = {
        country_id: countryID
      }
      const queryParams = {
        str: value
      }
      this.$store.dispatch('fetchService', { name: 'getZipcode', queryParams, params, onSuccess: (resp) =>  {
        const response = resp.data.zip_codes.map(val => ({'name': val, 'id': val})) //El array se convierte en objeto
        response.map((row) => {
          //se convierte en string porue da error con los otros types del payload al agregarse como option
          Object.keys(row).forEach((key) => {
            return row[key] = `${row[key]}`
          })
          row.text = row.name
        })
        this.$refs.formRenderPudoCountry.setManualOptions(name, response)
      }, hideAlert: true}) 
    },
    loadedLevels (origin) {
      const full_places = this.$generateArrayPlacesFromTree(this.levels)
      this.country = this.isAdmin ? this.formPudo.country : this.sessionUser.country
      this.fieldsPudoCountry = this.fieldsPudoCountry.filter(el => el.name.indexOf('skeleton') === -1).filter(el => !el.name.includes('level'))
      const indexSplice = this.fieldsPudoCountry.findIndex(el => el.name === 'country') + 1
      const country = {
        code: this.country.code.toLowerCase(),
        requiredLevels: this.internalLevels[this.country.code.toLowerCase()].required,
        displayLevels: this.internalLevels[this.country.code.toLowerCase()].display,
        maxLevel: Math.max(...this.internalLevels[this.country.code.toLowerCase()].required),
        levels: full_places,
        loadLevelsOnSelection: false,
        stepByStep: ['co', 'mx', 'pe'].includes(this.country.code.toLowerCase()),
        level3Search: ['mx'].includes(this.country.code.toLowerCase()) ? this.searchZipCode : null
      }
      const fieldOrigin = {
        fieldType: 'FieldsLevels',
        name: 'levels',
        extraName: `pudo_${country.code}`,
        country,
        dependency: 'country',
        change: this.changePlaceLevel,
        containerClass: 'col-sm-12 col-md-12 container-info' 
      }
      this.fieldsPudoCountry.splice(indexSplice, 0, fieldOrigin)
      const indexCourier = this.fieldsPudo.findIndex(el => el.name === 'courier')
      if (indexCourier !== -1) this.fieldsPudo[indexCourier].disabled = false
    },
    changePlaceLevel (name, value, next) { 
      this.nameCountry = name
      this.cleanLevels(name)
      const nextForCountry =  `${next}_${this.formPudo.country.code.toLowerCase()}`
      const level = this.nameCountry
      this.formPudo = {
        ...this.formPudo,
        [nextForCountry]: undefined,
        state_mx: next === 'null_pudo' ? '' : this.formPudo.state_mx,
        municipality_mx: next === 'null_pudo' ? '' : this.formPudo.municipality_mx
      }
      if (['co', 'mx', 'pe'].includes(this.formPudo.country.code.toLowerCase())) {
        // Salto de level en mx
        const currentLevel = +this.nameCountry.split('_')[0].replace('level', '')
        this.nextLevelData = {
          level: this.internalLevels[this.formPudo.country.code.toLowerCase()].required.length === currentLevel ? currentLevel : currentLevel + 1,
          parent_id: this.internalLevels[this.formPudo.country.code.toLowerCase()].required.length === currentLevel ? this.formPudo[level.replace(`level${currentLevel}`, `level${currentLevel > 1 ? currentLevel - 1 : currentLevel}`)].id : value.id,
          next: this.internalLevels[this.formPudo.country.code.toLowerCase()].required.length === currentLevel ? this.nameCountry : nextForCountry
        }
        // Cambio de queryParams en mx en el caso especial de saltar un nivel
        if (['level3_pudo_mx', 'level4_pudo_mx'].includes(this.nameCountry)) {
          this.nextLevelData.zip_code = this.nextLevelData.parent_id
          delete this.nextLevelData.parent_id
          delete this.nextLevelData.level
        }
      }
      this.getLevels(this.formPudo.country, true)

      
    },
    setManualOptions (places, fieldTo) {
      const full_places = this.$generateArrayPlacesFromTree(places)
      this.setLevelsParentMX(full_places)
      const level_num = +fieldTo.split('_')[0].replace('level', '')
      this.$refs.formRenderPudoCountry.setManualOptions(fieldTo, full_places.filter(place => place.level_num === level_num))
    },
    setLevelsParentMX (places) {
      const stateField  = {origin: 'fieldsPudoCountry', name: 'state_mx', level: 1}
      const municipalityField  = {origin: 'fieldsPudoCountry', name: 'municipality_mx', level: 2}
      if (['mx'].includes(this.formPudo.country.code.toLowerCase())) {
        const stateText = this.getPlaceTextByLevel(places, stateField.level)
        const municipalityText = this.getPlaceTextByLevel(places, municipalityField.level)
        this.updateFormField(stateField, stateText, 'col-sm-12 container-info')
        this.updateFormField(municipalityField, municipalityText, 'col-sm-12 container-info')
      } else {
        this.updateFormField(stateField, '', 'hide')
        this.updateFormField(municipalityField, '', 'hide')
      }
      
    },
    getPlaceTextByLevel(places, level) {
      if (places) {
        const place = places.find(p => p.level_num === level)
        return place ? place.text : ''
      } 
    },
    updateFormField(field, text, containerClass) {
      const selectedField = this[field.origin][this.getFieldIndex(this[field.origin], field.name)]
      if (selectedField) {
        selectedField.containerClass = containerClass
        this.formPudo[selectedField.name] = text || ''
      }
    },
    validateForm () {
      this.$refs.formRenderPudo.checkForm()
      this.$refs.formRenderPudoCountry.checkForm()
    },
    close() {
      this.$emit('closeModal')
    },
    handleSubmitPudo () {
      const arrayType = this.pudo.type.name.split(',')
      const formattedType = this.formPudo.organization_id?.id && !this.formPudo.shipper_id ? arrayType[1] : arrayType[0]
      if (!!this.formPudo.address && Object.prototype.hasOwnProperty.call(this.formPudo.address, 'formatted_address')) {
        this.loading.createPudo = true
        this.lastLevelOfPlace = [1, 4].includes(this.formPudo.country?.id) && this.formPudo[`level3_pudo_${this.formPudo.country.code.toLowerCase()}`] ? this.formPudo[`level3_pudo_${this.formPudo.country.code.toLowerCase()}`] : [3].includes(this.formPudo.country?.id) && this.formPudo.level2_pudo_co ? this.formPudo.level2_pudo_co : [2].includes(this.formPudo.country?.id) && this.formPudo.level4_pudo_mx ? this.formPudo.level4_pudo_mx : null 
        const payload = {
          code: this.formPudo.code,
          name: this.formPudo.name,
          pudo_type: formattedType,
          organization_id: this.formPudo.organization_id?.id ? this.formPudo.organization_id?.id : null,
          shipper_id: this.formPudo.shipper_id?.id ? this.formPudo.shipper_id?.id : null,
          carrier_code: this.formPudo.courier?.id ? this.formPudo.courier?.code : null,
          country_code: this.formPudo.country.code,
          place_id: this.lastLevelOfPlace.id,
          platform_place_id: this.lastLevelOfPlace.platform_id,
          deadline_days: Number(this.formPudo.deadline_days) || null,
          users: this.formPudo?.emails.length > 0 ? this.formPudo.emails : null,
          address: {
            full_address: this.formPudo.address.formatted_address,
            information: this.formPudo.information || null,
            location: {
              latitude: this.formPudo.address.location.lat.toString(),
              longitude: this.formPudo.address.location.lng.toString()
            }
          } 
        }
        const params = {}
        const queryParams = {...payload}
        this.$store.dispatch('fetchService', { name: 'postPudo', params, queryParams, onSuccess: () => this.onSuccessPostPudo(), onError: (err) => this.onErrorPostPudo(err), hideAlert: true})
      } else {
        this.$alert('Dirección inválida')
      }
    },
    onSuccessPostPudo () {
      this.loading.createPudo = false
      this.$success(this.$t('ms-alerta-nuevo-pudo'))
      this.close()
      this.$emit('savedPudo')
    },
    onErrorPostPudo (error) {
      this.loading.createPudo = false
      this.$alert(this.$t('msg-problema-guardar', {code: error}))
    },
    cleanLevels (name) {
      this.formPudo = {
        ...this.formPudo,
        address: null,
        information: null
      }
      this.nameCountry =  ['level3_pudo', 'level2_pudo', 'level4_pudo'].includes(name) ? `${name}_${this.formPudo.country.code.toLowerCase()}` : name
      if (['level3_pudo', 'level2_pudo'].includes(name)) {
        delete this.formPudo.level3_pudo_pe
        delete this.formPudo.level2_pudo_co
        delete this.formPudo.level2_pudo_mx
      }
      if (['level4_pudo'].includes(name)) {
        delete this.formPudo.level4_pudo_mx
      }
    },
    getFullAddress (value) {
      let text = `${value}, `
      if (this.formPudo.country?.id) {
        this.internalLevels[this.formPudo.country.code.toLowerCase()].required.map(level => {
          if (this.formPudo[`level${level}_pudo_${this.formPudo.country.code.toLowerCase()}`] && this.formPudo[`level${level}_pudo_${this.formPudo.country.code.toLowerCase()}`].id) {
            text += `${this.formPudo[`level${level}_pudo_${this.formPudo.country.code.toLowerCase()}`].text}, `
          }
          text = text.substring(0, text.length - 1)
        })
      }
      return text
    },
    searchGoogleAddress (value) {
      value = this.getFullAddress(value)
      return this.baseService.callService('getGoogleAddress', {address: value, country_id: this.formPudo.country.id})
        .then(resp => {
          const validateAddress = resp.data.filter((el, index) => {
            const indexStreetName = el.address_components.findIndex(el => el.types.includes('route'))
            const indexStreetNumber = el.address_components.findIndex(el => el.types.includes('street_number'))
            return indexStreetName !== -1 && indexStreetNumber !== -1
          })
          const listAddress = validateAddress.length > 0 ? validateAddress : resp.data
          const resultAddress = listAddress.map(address => {
            return {
              id: address.place_id,
              formatted_address: address.formatted_address,
              text: address.formatted_address,
              location: address.geometry.location
            }
          })
          resultAddress.suggestions_address_validate_google = validateAddress.length === 0
          return resultAddress
        
        })
    },
    changeCourier (name, value) {
      if (value?.id) {
        const filterCountry = this.countries.filter(item => item.id === value.country_id)
        if (this.formPudo.country === undefined || (this.formPudo.country?.id !== filterCountry[0].id)) {
          const indexCourier = this.fieldsPudo.findIndex(el => el.name === 'courier')
          this.fieldsPudo[indexCourier].disabled = true
          this.formPudo.country = filterCountry[0]
          this.changeCountry('country', this.formPudo.country)
        }
      } else {
        this.formPudo.country = null
        this.changeCountry('country', this.formPudo.country)
      } 
      
    },
    getOrganization (value, index) {
      if (!value?.id) return
      this.baseService.callService('getOrganization', null, { id: value.id })
        .then(resp => {
          this.fieldsPudo[index].disabled = false
          const resultCountry = resp.data.country
          if (this.formPudo.country === undefined || (this.formPudo.country?.id !== resultCountry.id)) {
            this.formPudo.country = resultCountry
            this.changeCountry('country', this.formPudo.country) 
          } 
          this.keyFormRenderPudo++
        })
        .catch(err => {
          console.error(err)
        })
    },
    getFieldIndex(field, value) {
      return field.findIndex(el => el.name === value)
    }
  }
}
</script>
<style lang="scss">
    .padding-button {
        padding: 0.7rem 2rem;
    }
    .customize-date{
        margin-left: 1rem;
        opacity: 0.5;
        color: #5C5C5C;
    }
    .custom-button {
      padding: 0.8rem;
      margin-left: 0.6rem;
      border-radius: 5px !important;
    }
    .custom-input-email {
      border-top-right-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;
      height: 2.7rem;
    }
    .margin-forms {
      margin: 0 1.5rem;
    }
    .feather-mail{
      width: 20px;
      height: 20px;
      stroke-width: 1;
    }
    .custom-remove-tag{
      color: #1D192B;
      padding: 0 0.4rem;
      font-weight: bold;
    }
    .custom-icon-email {
      background: #FAFAFA;
      display: flex;
      align-items: center;
      border: 1px solid #d8d6de;
      padding: 0rem 0.9rem;
      border-top-left-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem;
    }
    .custom-tags {
      border: none;
      padding: 0 !important;
    }
    .b-form-tags:focus, .b-form-tags.focus{
      background-color: none !important;
      box-shadow: none !important;
    }
    .badge-custom-email {
      background-color: #F5D098;
      color: #1D192B;
      display: flex;
      align-items: center;
    }
    .margin-li-tags{
      margin: 1rem 0.5rem 0rem 0rem;
    }
    .margin-text-invalid {
      margin-top: 1rem;
    }
    .container-form {
      display: grid;
      grid-template-columns: 50% 50%;
    }
    @media (max-width: 870px) {
      .badge {
        font-size: 70%;
      }
      .margin-forms .row {
        display: flex;
      }
    }
</style>