<template>
    <b-modal
      id="modalCreatePudo"
      :modal-class="size_modal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      class="modal-create-pudo"
    >
    <template #modal-title>
        <h3>Crear Puntos Retiro</h3>
    </template>

        <b-tabs align="left" class="tab-primary" v-model="tabIndex">
            <b-tab @click="changeSizeModal(CONDITIONS.INDIVIDUAL)">
            <template #title>
              <div class="tab-title">
              <span>{{$t('Individual')}}</span>
              </div>
            </template>
            <b-card>
            <individual :pudo="pudo" :carriers="carriers" @closeModal="closeModal" @savedPudo="savedPudo"/>
            </b-card>
          </b-tab>
          <b-tab @click="changeSizeModal(CONDITIONS.MASSIVE)">
            <template #title>
              <div>
              <span> {{$t('Masivo')}} </span>
              </div>
            </template>
            <b-card>
              <massive  @closeModal="closeModal" @savedPudo="savedPudo"/>
            </b-card> 
          </b-tab>
        </b-tabs>
    </b-modal>
  </template>

<script>
import individual from './components/Individual.vue'
import massive from './components/Massive.vue'

export default {
  name: 'modal-create-pudo',
  components: { individual, massive },
  props: ['pudo', 'carriers', 'getPudos', 'pagination'],

  data() {
    return {
      tabIndex: 0,
      size_modal: 'dialog-1200',
      CONDITIONS: Object.freeze({
        INDIVIDUAL: 'individual',
        MASSIVE: 'massive'
      })
    }
  },
  methods: {    
    async savedPudo () {
      if (this.pagination.page !== 1) this.pagination.page = 1
      else await this.getPudos(this.pudo.type.name)
    },
    closeModal () {
      this.$bvModal.hide('modalCreatePudo')
    },
    changeSizeModal (type) {
      this.size_modal = type === this.CONDITIONS.MASSIVE ? 'dialog-600' : 'dialog-1200'
    }
  }
}
</script>

<style lang="scss">
  .modal-body {
    padding: 2rem !important;
  }
</style>