<template>
    <b-modal id="modalFilterDeliveries" :title="$t('Búsqueda Avanzada')" modal-class="custom-dialog dialog-600" :ok-title="$t('Buscar')" ok-variant="warning" 
  ok-only no-close-on-esc no-close-on-backdrop centered @close="close" @ok="ok" :ok-disabled="loading">
      <form-render v-show="!loading" :form.sync="form" :key="keyFormRender" :fields="fields" containerButtonsClass="col-sm-12" ref="formRenderFilter" @send="onAccept">
      </form-render>
      <div v-show="loading">
        <b-skeleton width="30%"/>
        <b-skeleton type="input"/>
        <b-skeleton width="30%" class="mt-1"/>
        <b-skeleton type="input"/>
      </div>
    </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name:'modalFilterDeliveries',
  props: ['filter', 'countries', 'searchNamePudo', 'formattedOrganization', 'shippersByOrganization', 'deliveryStatusesFull'],
  data () {
    return {
      form: {},
      fields: [],
      loading: true,
      keyFormRender: 0,
      nextLevelData: {},
      internalLevels: {
        cl: {
          required: [3],
          display: [3]
        },
        co: {
          required: [1, 2],
          display: [1, 2]
        },
        pe: {
          required: [1, 2, 3],
          display: [2, 3]
        },
        ar: {
          required: [1, 2, 3],
          display: [1, 2, 3]
        },
        mx: {
          required: [1, 2, 3, 4],
          display: [1, 2, 4]
        },
        ec: {
          required: [1, 2, 3],
          display: [1, 2, 3]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      levels: 'getLevels'
    })
  },
  watch: {
    filter () {
      this.form = {
        ...this.filter
      }
      this.setInitialData()
      if ([1].includes(this.form['organization.id']?.organization_type.id)) {
        const index = this.fields.findIndex(el => el.name === 'shipper.id')
        this.fields[index].containerClass = 'col-sm-12 col-md-12 container-info'
      } 
      if (this.shippersByOrganization.length > 0) this.setSelectOptions('shipper.id', { options: this.shippersByOrganization })
      this.loading = false
      this.keyFormRender++
    },
    countries () { 
      this.setCountries()
    },
    formattedOrganization () {
      this.setOrganizations()
    },
    shippersByOrganization () {
      this.setSelectOptions('shipper.id', { options: this.shippersByOrganization })
    },
    deliveryStatusesFull () {
      this.setStatusDeliveries()
    }
  },
  methods: {
    setInitialData () {
      this.fields = [ 
        {fieldType: 'FieldSelect', name: 'organization.id', label: 'Empresa', useLabel: true, clearable: true, change: this.changeOrganization, containerClass: 'col-sm-12 col-md-12 container-info'},
        {fieldType: 'FieldSelect', name: 'shipper.id', label: 'Seller', useLabel: true, clearable: true, dependency: 'organization.id', containerClass: 'hide', useSkeleton: false, change: this.changeShipper},
        {fieldType: 'FieldAutocomplete', name: 'pudo_id', label: 'Punto de retiro', searchOnType: { fx: this.searchPudo, nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true }, placeholder:'Ingrese punto de retiro', clearable: true, containerClass: 'col-sm-12 col-md-12 container-info'},
        {fieldType: 'FieldInput', name: 'imported_id', label: 'N° de envío', containerClass: 'col-sm-12 col-md-12 container-info'},
        {fieldType: 'FieldSelect', name: 'status', label: 'Estado del envío', containerClass: 'col-sm-12 col-md-12 container-info'},
        {fieldType: 'FieldDatepicker', name: 'creation_date', label: 'Fecha de creación', containerClass: 'col-sm-12 col-md-12 container-info', range: true},
        {fieldType: 'FieldSelect', name: 'country', label: 'País', options: this.formattedCountry, containerClass: 'col-sm-12 col-md-12 container-info', change: this.changeCountry, addFlags: true}
      ]
      this.setOrganizations()
      this.setCountries()
      this.setStatusDeliveries()
      this.sessionUser = this.$session.get('cas_user')
      this.isAdmin = ['superadmin', 'admin'].includes(this.sessionUser.role)
      if (!this.isAdmin) this.setDinamicFields()
    },
    setOrganizations () {
      if (this.formattedOrganization && !!this.formattedOrganization.length) {
        this.setSelectOptions('organization.id', { options: this.formattedOrganization })
        this.loading = false
      }
    },
    setCountries () {
      if (this.countries && !!this.countries.length) {
        this.formattedCountry = this.countries.filter(item => {
          return item.code.toLowerCase() !== 'ar' && item.code.toLowerCase() !== 'ec'
        })
        this.setSelectOptions('country', { options: this.formattedCountry }, 'country')
        this.loading = false
      }
    },
    setStatusDeliveries () {
      if (this.deliveryStatusesFull && !!this.deliveryStatusesFull.length) {
        this.setSelectOptions('status', { options: this.deliveryStatusesFull })
      }
    },
    setDinamicFields () {
      const indexCompany = this.fields.findIndex(el => el.label === 'Empresa')
      const indexCountry = this.fields.findIndex(el => el.label === 'País')
      this.fields[indexCompany].containerClass = 'hide'
      this.fields[indexCountry].containerClass = 'hide'
    },
    close () {
      this.$emit('update:filter', {})
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderFilter.checkForm()
    },
    onAccept (data) {
      this.loading = true
      this.lastLevelOfPlace = [1, 4].includes(data.country?.id) && data.level3_pudo ? data.level3_pudo : [3].includes(data.country?.id) && data.level2_pudo ? data.level2_pudo : [2].includes(data.country?.id) && data.level4_pudo ? data.level4_pudo : null
      const form = {
        'created_at__gte': data.creation_date?.start ? this.$options.filters.moment(data.creation_date?.start, 'YYYY-MM-DD') : '',
        'created_at__lte': data.creation_date?.end ? this.$options.filters.moment(data.creation_date?.end, 'YYYY-MM-DD') : '',
        'organization.id': data['organization.id'] ? data['organization.id'] : '',
        'shipper.id': data['shipper.id'] ? data['shipper.id'] : '',
        'pudo_id': data.pudo_id ? data.pudo_id : '',
        'imported_id': data.imported_id ? data.imported_id : '',
        'status.name': data.status ? data.status.name : '',
        'country.id': data.country ? data.country.id : '',
        'address.place.level1.id': this.lastLevelOfPlace ? this.lastLevelOfPlace : ''
      }
      // Elimina los valores vacios
      Object.keys(form).map(function(key, value) {
        if (form[key] === '') delete form[key]
      })
      this.$emit('send', { form, original: data})
    },
    async searchPudo(value) {
      const data = await this.searchNamePudo(value, this.form)
      return data
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    changeOrganization (name, value) {
      const index = this.fields.findIndex(el => el.name === 'shipper.id')
      if (!!value?.id) {
        this.fields[index].containerClass = value.organization_type.id === 2 ? 'hide' : 'col-sm-12 col-md-12 container-info'
        this.form = {
          ...this.form,
          'shipper.id': null,
          'pudo_id': null
        }
        this.fields[index].options = []
        const queryParams = {}
        const params = {
          organization_id: !!this.organizationId ? this.organizationId.id : this.form['organization.id']?.id || 0
        }
        this.fields[index].useSkeleton = true
        this.$store.dispatch('fetchService', { name: 'getShippersByOrganizationSimplifiedQueryParams', queryParams, params, onSuccess: (resp) =>  {
          this.fields[index].useSkeleton = false
          if (value.organization_type.id === 2) {
            this.form['shipper.id'] = {id: resp.data[0].id, text: resp.data[0].name}
          }
        }})
      } else {
        this.fields[index].containerClass = 'hide'
        this.form['shipper.id'] = null
        this.form['organization.id'] = null
        this.keyFormRender++
        return null
      }   
      
    },
    changeCountry (name, value) {
      // Clean fields
      this.form.level1_pudo = undefined
      this.form.level2_pudo = undefined
      this.form.level3_pudo = undefined
      this.form.level4_pudo = undefined

      this.fields = this.fields.filter(el => !el.name.includes('level') && !el.name.includes('skeleton'))
      this.country = value
      this.form = {
        ...this.form,
        country: value
      }
      //limpia todo
      if (!value?.id) return null
      //Guardo los campos normales
      let indexFromCountry = this.fields.findIndex(el => el.name === name)

      indexFromCountry++
      const display = this.internalLevels[this.country.code.toLowerCase()].display

      display.map(el => {
        this.fields.push({ name: `skeleton${el}`, useSkeleton: true, containerClass: 'col-sm-12 col-md-12 container-info' })
      })

      this.fields.splice(indexFromCountry + 1, 0)
      //Pego los campos originales
      if (value?.id) {
        this.keyServiceType++
        this.nextLevelData = {}
        if (['mx'].includes(value.code.toLowerCase())) {
          this.nextLevelData.level = 1
        }
        this.getLevels(value, false)
      }
      
    },
    getLevels (country, forceGetLevels) {
      const params = {country: country.code.toLowerCase() }
      const { queryParams, onSuccessFx } = this.getQueryParamLevel(country.code.toLowerCase())
      if (!['mx'].includes(params.country) || forceGetLevels) {
        this.$store.dispatch('fetchService', { name: 'getLevels', params, queryParams, onSuccess: (data1, data2) => onSuccessFx(data1, data2)})
      } else {
        this.$store.commit('setLevels', [])
        this.loadedLevels('getLevels')
      }
    },
    getQueryParamLevel (country_code) {
      let queryParams = {}
      let onSuccessFx = () => this.loadedLevels('getQueryParamLevel')
      if (['co', 'mx', 'pe'].includes(country_code)) {
        queryParams = {...this.nextLevelData}
        delete queryParams.next
        if (this.nextLevelData.next) {
          onSuccessFx = (data) => {
            this.setManualOptions(data.data.level1, this.nextLevelData.next)
          }
        }
      }
      return { queryParams, onSuccessFx }
    },
    searchLevels (name, value) {
      const level = name.split('_')[0].replace('level', '')
      const queryParams = {
        level,
        name: value
      }
      const params = {
        country: this.form.country.code.toLowerCase()
      }
      if (this.form[name.replace(level, level + 1)]) {
        queryParams.parent_id = this.form[name.replace(level, level + 1)].id
      }
      this.$store.dispatch('fetchService', 
        { name: 'getLevels', params, queryParams, onSuccess: (data) => this.setManualOptions(data.data?.level1 || [], name), hideAlert: true}
      )
    },
    loadedLevels (origin) {
      const full_places = this.$generateArrayPlacesFromTree(this.levels)
      this.country = this.isAdmin ? this.form.country : this.sessionUser.country
      this.fields = this.fields.filter(el => el.name.indexOf('skeleton') === -1).filter(el => !el.name.includes('level'))
      const indexSplice = this.fields.findIndex(el => el.name === 'country') + 1
      const country = {
        code: this.country.code.toLowerCase(),
        requiredLevels: [0],
        displayLevels: this.internalLevels[this.country.code.toLowerCase()].display,
        maxLevel: Math.max(...this.internalLevels[this.country.code.toLowerCase()].required),
        levels: full_places,
        loadLevelsOnSelection: false,
        stepByStep: ['co', 'mx', 'pe'].includes(this.country.code.toLowerCase()),
        level1Search: this.searchLevels
      }
      const fieldOrigin = {
        fieldType: 'FieldsLevels',
        name: 'levels',
        extraName: 'pudo',
        country,
        containerClass: 'col-sm-12 col-md-12 container-info',
        change: this.changePlaceLevel
      }
      this.fields.splice(indexSplice, 0, fieldOrigin)

    },
    changePlaceLevel (name, value, next) {
      const countryCode = this.form.country.code.toLowerCase()
      const mx = ['mx'].includes(countryCode)
      this.requireLastLevel(countryCode, mx, next)
      const level = name
      this.form = {
        ...this.form,
        [next]: undefined
      }
      if (['co', 'mx', 'pe'].includes(countryCode)) {
        const currentLevel = (name === 'level2_pudo') && mx ? 3 : +name.split('_')[0].replace('level', '')
        this.nextLevelData = {
          level: this.internalLevels[countryCode].required.length === currentLevel ? currentLevel : currentLevel + 1,
          parent_id: this.internalLevels[countryCode].required.length === currentLevel ? this.form[level.replace(`level${currentLevel}`, `level${currentLevel > 1 && currentLevel === 4 ? currentLevel - 2 : currentLevel > 1 && currentLevel !== 4 ? currentLevel - 1 : currentLevel}`)].id : value.id,
          next: this.internalLevels[countryCode].required.length === currentLevel ? name : next
        }
        if ((name === 'level2_pudo' || name === 'level4_pudo') && mx) {
          this.nextLevelData.grandparent_id = this.nextLevelData.parent_id
          delete this.nextLevelData.parent_id
        }
      }
      this.getLevels(this.form.country, true)    
    },
    setManualOptions (places, fieldTo) {
      const full_places = this.$generateArrayPlacesFromTree(places)
      const level_num = +fieldTo.split('_')[0].replace('level', '')
      this.$refs.formRenderFilter.setManualOptions(fieldTo, full_places.filter(place => place.level_num === level_num))
    },
    // Es necesario para requirir el ultimo nivel solo en el caso que se realice un change en el primero
    // Cuando ya se pueda filtrar por cualquier level no deberia ser necesario
    requireLastLevel (countryCode, mx, next) {
      const penultimate = this.internalLevels[countryCode].display.slice(-2, -1)
      const last = this.internalLevels[countryCode].display[this.internalLevels[countryCode].display.length - 1]
      const indexLevel = this.fields.findIndex(el => el.name === 'levels')
      const fieldLevelRequired = this.fields[indexLevel].country.requiredLevels
      // Requerir ultimo nivel
      if (!!this.form[`level${penultimate}_pudo`] || (!!this.form.level1_pudo && mx)) {
        if (fieldLevelRequired[0] === 0) {
          this.setRequiredLevels(indexLevel, last)
        }
      }
      // Eliminar requerido del ultimo nivel
      if ((!mx && next === 'null_pudo') || (mx && !this.form.level1_pudo)) {
        if (fieldLevelRequired[0] !== 0) {
          this.setRequiredLevels(indexLevel, 0)
        } 
      }
    },
    setRequiredLevels (indexLevel, number) {
      this.fields[indexLevel].country.requiredLevels = [number]
      this.keyFormRender++
    }
  }
}
</script>