<template>
    <div>
    <form-render
    ref="formRenderStatusCompany"
    :form.sync="formCountry"
    :fields="fieldsCountry"
    :key="keyFormRenderCountry"
    />
    <small>Esta acción puede tomar varios minutos</small>
    <p class="mt-3">Archivo de importación (<a class="lnk lnk-primary custom-link" :href="excelDocUrl" target="_blank" rel="noopener noreferrer">Descargar Formato</a>)</p>
    <div class="row" >
      <div class="col-md-10">
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="form.filePudo" v-cloak>
          <div class="w-100 p-2 file-drop-area">
            <b-input-group class="invoice-list-area">
              <b-form-input
                  placeholder="File name..."
                  v-model="form.filePudo.name"
                  class="bg-gray-input border-0"
              />
              <b-input-group-append class="bg-gray-input">
                <b-button variant="outline" class="border-0" size="sm" @click="removeFilePudo()" :disabled="loading.uploadPudo">
                  <feather-icon icon="XIcon" size="20"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="!form.filePudo">
          <div class="file-drop-area" >
            <input type="file" name="filePudo" id="filePudo" class="file-input invisible" @change="changeFilePudo" accept=".xls, .xlsx"/>
            <label for="filePudo" class="w-100 text-center cursor-pointer m-0 custom-label">
              <feather-icon v-if="!loading.uploadPudo" icon="UploadCloudIcon" size="22"/>
              <span v-if="!loading.uploadPudo" >Seleccionar archivo</span>
            </label>
          </div>
        </b-col>
      </div>
      <div class="col-md-12 d-flex align-items-center justify-content-end mt-3">
        <b-button  variant="warning" @click="uploadPudoFile()" :disabled="loading.uploadPudo || !form.filePudo">
          <b-spinner v-if="loading.uploadPudo" small></b-spinner>
          <span v-else>Crear</span>
        </b-button>
      </div> 
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { environment } from '@/environments/environment'
import BaseServices from '@/store/services/index'
export default {
  name: 'massive',
  props: ['closeModal'],
  data() {
    return { 
      excelDocUrl: null,
      form: {
        filePudo: null
      },
      formCountry: {},
      fieldsCountry: [],
      loading: {
        uploadPudo: false
      },
      baseService: new BaseServices(this),
      sessionUser: null,
      keyFormRenderCountry: 0
    }
  },  
  mounted() {
    this.setInitialData()
  },
  watch: {
    countries () {
      this.setCountries()
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries'
    })
  },
  methods: {
    setCountries() {
      if (this.countries && !!this.countries.length) {
        this.formattedCountry = this.countries.filter(item => {
          return item.code.toLowerCase() !== 'ar' && item.code.toLowerCase() !== 'ec'
        })
        this.setSelectOptions('country', { options: this.formattedCountry }, this.fieldsCountry)
        this.formCountry.country = this.countries.find(item => item.code === 'CL')
        this.countryCode = this.formCountry.country.code
      }
    },
    setSelectOptions (name, { options }, field) {
      const index = field.findIndex(el => el.name === name)
      if (index !== -1) {
        field[index].options = options
        this.keyFormRenderPudo++
      }
    },
    uploadPudoFile () {
      this.loading.uploadPudo = true
      const data = {
        file: this.form.filePudo,
        user: this.sessionUser?.id,
        email: this.sessionUser?.email,
        country_code: this.countryCode
      }
      const params = {}
      this.baseService.callUploadFileCustomParams('postPudoMassive', data, params)
        .then(response => {
          this.$success(this.$t('msg-exito-importar-archivo-email'))
          this.close()
          this.$emit('savedPudo')
        })
        .catch((err) => {     
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.error}))
        })
        .finally(() => {
          this.loading.uploadPudo = false
        }) 
    },
    changeFilePudo(e) {
      this.form.filePudo = e.target.files[0]
    },
    removeFilePudo() {
      this.form.filePudo = null
      this.keyFormRender++
    },
    close() {
      this.removeFilePudo()
      this.loading.uploadPudo = false
      this.$emit('closeModal')
    },
    setInitialData () {
      // Obtener sesión de usuario
      this.sessionUser = this.$session.get('cas_user')
      this.isAdmin = ['admin', 'superadmin'].includes(this.sessionUser.role)
      this.countryCode = this.sessionUser.country.code
      if (this.isAdmin) {
        this.fieldsCountry = [
          {fieldType: 'FieldSelect', label: this.$t('País'), name: 'country', addFlags:true, useLabel: true, clearable:false, containerClass: 'col-md-6 container-info', change: this.changeCountry}
        ]
        this.setCountries()
      } 
      this.excelDocByCountry(this.countryCode)
    },
    excelDocByCountry (country_code) {
      this.excelDocUrl = `${environment.dubaiDocsCloud}/pudos/bulk-import-formats/${country_code.toLowerCase()}.xlsx`
    },
    changeCountry (name, value) {
      this.removeFilePudo()
      this.countryCode = value.code
      this.excelDocByCountry(this.countryCode)
    }
  }
}
</script>
<style lang="scss" scoped>
  div.file-drop-area {
    padding: 0.4rem 0;
    border: 1.7px solid #B9B9C7;
    border-radius: 0.357rem;
    max-height: 300px;
    overflow-y: auto;
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
  .invoice-list-area{
    padding-bottom: 8px;
  }
  .bg-gray-input {
    background-color: #f7f7f9 !important;
  }
  .custom-label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #AEAEAE;
    font-size: 15px;
  }
  .custom-label svg {
    stroke-width: 1;
    margin-right: 0.5rem;
  }
  .custom-link {
    text-decoration: underline;
    color: #1B4284 !important;
    font-weight: bold;
  }
</style>