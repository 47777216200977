<template>
    <b-modal id="modalFilterAdvanced" :title="$t('Búsqueda Avanzada')" modal-class="custom-dialog dialog-600" :ok-title="$t('Buscar')" ok-variant="warning" 
  ok-only no-close-on-esc no-close-on-backdrop centered @close="close" @ok="ok" :ok-disabled="loading">
      <form-render v-show="!loading" :form.sync="form" :key="keyFormRender" :fields="fields" containerButtonsClass="col-sm-12" ref="formRenderFilter" @send="onAccept">
      </form-render>
      <div v-show="loading">
        <b-skeleton width="30%"/>
        <b-skeleton type="input"/>
        <b-skeleton width="30%" class="mt-1"/>
        <b-skeleton type="input"/>
      </div>
    </b-modal>
  </template>
  
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
export default {
  name:'modalFilterAdvanced',
  props: ['filter', 'formattedCountry', 'searchNameCourier', 'mySession', 'filterPudosByQuery'],
  data () {
    return {
      form: {},
      fields: [],
      loading: true,
      keyFormRender: 0,
      status: [
        {id: true, text: 'Habilitado'},
        {id: false, text: 'Deshabilitado'}
      ],
      nextLevelData: {},
      internalLevels: {
        cl: {
          required: [3],
          display: [3]
        },
        co: {
          required: [1, 2],
          display: [1, 2]
        },
        pe: {
          required: [1, 2, 3],
          display: [2, 3]
        },
        ar: {
          required: [1, 2, 3],
          display: [1, 2, 3]
        },
        mx: {
          required: [1, 2, 3, 4],
          display: [1, 2, 4]
        },
        ec: {
          required: [1, 2, 3],
          display: [1, 2, 3]
        }
      },
      fieldSkipLine: { name: 'line', useSkeleton: true, skipLine: true, containerClass: 'col-12' },
      formattedOrganization: null,
      forceSetMxLevel4: false,
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      levels: 'getLevels',
      shippersByOrganizationSimplifiedQueryParams: 'getShippersByOrganizationSimplifiedQueryParams',
      organizations: 'getOrganizationsGlobal'
    })
  },
  watch: {
    filter (value) {
      if (!!value) {
        if ((this.form.country?.id !== this.filter.country?.id) && !!this.form.organization_id) {
          delete this.form.organization_id
          delete this.form.shipper_id
        }
        this.form = {
          ...this.form,
          ...this.filter,
          courier: this.filter['courier.id'],
          shipper_id: this.filter['owner.id']
        }
        if (!!this.filterPudosByQuery) {
          this.form.organization_id = this.formattedOrganization.filter(el => el.id === Number(this.$route.query.company_id))[0]
        }
        this.setInitialData()
        this.loading = false
        this.keyFormRender++
      }
    },
    formattedCountry () { 
      this.setCountries()
    },
    organizations () {
      this.setOrganizations()
    },
    shippersByOrganizationSimplifiedQueryParams (value) {
      this.setSelectOptions('shipper_id', { options: value })
    },
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [ 
        {fieldType: 'FieldInput', name: 'name__like__or__code__like', label: 'Nombre/Código Punto de retiro', containerClass: 'col-sm-12 col-md-12 container-info', placeholder:'Ingrese Nombre o Código Punto de retiro'},
        {fieldType: 'FieldSelect', name: 'activited', label: 'Estado', containerClass: 'col-sm-12 col-md-12 container-info', options: this.status},
        {fieldType: 'FieldDatepicker', name: 'creation_date', label: 'Fecha de creación', containerClass: 'col-sm-12 col-md-12 container-info', range: true},
        {fieldType: 'FieldSelect', name: 'country', label: 'País', options: this.formattedCountry, containerClass: 'col-sm-12 col-md-12 container-info', change: this.changeCountry, addFlags: true, disabled: false}
      ]
      this.typePudoFieldChange()
      this.setShippers()
      this.setLevelsByRole()
      this.setOrganizations()
      this.setCountries()
    },
    setShippers () {
      if (this.shippersByOrganizationSimplifiedQueryParams && !!this.shippersByOrganizationSimplifiedQueryParams.length) {
        this.setSelectOptions('shipper_id', { options: this.shippersByOrganizationSimplifiedQueryParams })
      }
    },
    setOrganizations () {
      if (this.organizations && !!this.organizations.length) {
        this.formattedOrganization = this.organizations.map(el => {
          if (el.organization_type.id === 1) {
            return {...el, text: `${el.name} (M)`}
          } else {
            return {...el, text: el.name}
          }
        })
        this.setSelectOptions('organization_id', { options: this.formattedOrganization })
        this.loading = false
      }
    },
    setCountries () {
      if (this.formattedCountry && !!this.formattedCountry.length) {
        this.setSelectOptions('country', { options: this.formattedCountry }, 'country')
        this.loading = false
      }
    },
    setLevelsByRole () {
      this.sessionUser = this.$session.get('cas_user')
      this.isAdmin = ['admin', 'superadmin'].includes(this.sessionUser.role)
      this.setCountry = !!this.isAdmin ? this.form.country : this.sessionUser.country
      if (!!this.form?.country) {
        if (!this.isAdmin) {
          this.indexCountry = this.fields.findIndex(el => el.name === 'country')
          this.fields[this.indexCountry].containerClass = 'hidden'
          this.form.level2_pudo || this.form.level1_pudo ? this.changeCountry('country', this.setCountry, true, true) : this.changeCountry('country', this.setCountry, true)
        } 
        else if (this.isAdmin && !this.form.courier) this.changeCountry('country', this.setCountry, true)    
      }
      if (!!this.form.courier && !!this.isAdmin) this.setCountryByCarrier()
      if (!!this.form.organization_id && !!this.isAdmin) this.changeOrganization('organization_id', this.form.organization_id, true)
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderFilter.checkForm()
    },
    close () {
      this.$bvModal.hide('modalFilterAdvanced')
    },
    onAccept (data) {
      this.loading = true
      this.lastLevelOfPlace = [1, 4].includes(data.country?.id) && data.level3_pudo ? data.level3_pudo : [3].includes(data.country?.id) && data.level2_pudo ? data.level2_pudo : [2].includes(data.country?.id) && data.level4_pudo ? data.level4_pudo : null
      const form = {
        'created_at__gte': data.creation_date?.start ? this.$options.filters.moment(data.creation_date?.start, 'YYYY-MM-DD') : '',
        'created_at__lte': data.creation_date?.end ? this.$options.filters.moment(data.creation_date?.end, 'YYYY-MM-DD') : '',
        'owner.organization.id': data.organization_id ? data.organization_id.id : '',
        'owner.id': data.shipper_id ? data.shipper_id : '',
        'courier.id': data.courier ? data.courier : '',
        'activated': data.activited ? data.activited.id : '',
        'name__like__or__code__like': data.name__like__or__code__like ? data.name__like__or__code__like : '',
        'country.id': data.country ? data.country : '',
        'address.place.level1.id': this.lastLevelOfPlace ? this.lastLevelOfPlace : ''
      }
      // Elimina los valores vacios
      Object.keys(form).map(function(key, value) {
        if (form[key] === '') delete form[key]
      })
      this.$emit('send', { form, original: data})
    },
    async searchCarrier(value) {
      const data = await this.searchNameCourier(value)
      return data
    },
    typePudoFieldChange () {
      switch (this.form.type?.id) {
      case 1:
        this.fields.splice(1, 0, {fieldType: 'FieldAutocomplete', name: 'courier', label: 'Courier', containerClass: 'col-sm-12 col-md-12 container-info', searchOnType: { fx: this.searchCarrier, nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true }, placeholder:'Ingrese Courier', clearable: true, change: this.changeCourier})
        if (!this.isAdmin) {
          this.indexCreationDate = this.fields.findIndex(el => el.name === 'creation_date')
          this.fields.splice(this.indexCreationDate, 1)
        }
        break
      case 2:
        this.fields.splice(1, 0, 
          {fieldType: 'FieldSelect', name: 'organization_id', label: 'Empresa', useLabel: true, change: this.changeOrganization, containerClass: 'col-sm-12 col-md-12 container-info' }, 
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, dependency: 'organization_id', containerClass: 'hide', useSkeleton: false})
        break
      case 5:
        this.indexCreationDate = this.fields.findIndex(el => el.name === 'creation_date')
        this.fields.splice(this.indexCreationDate, 1)
        break
      case 4:
        this.fields.splice(1, 0, 
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Seleccione Seller', useSkeleton: false, containerClass: 'col-sm-12  container-info'})
        break
        
      default:
        break
      }
    },
    changeOrganization (name, value, forcedChange) {
      const index = this.fields.findIndex(el => el.name === 'shipper_id')
      if (!!value?.id) {
        this.getOrganization(value)
        this.fields[index].containerClass = value.organization_type.id === 2 ? 'hide' : 'col-sm-12  container-info'
        const notResetShipper = forcedChange && !!this.form.shipper_id
        this.form.shipper_id = notResetShipper ? this.form.shipper_id : null
        this.form = {
          ...this.form,
          shipper_id: notResetShipper ? this.form.shipper_id : null
        } 
        this.fields[index].options = []
        const queryParams = {}
        const params = {
          organization_id: !!this.organizationId ? this.organizationId.id : this.form.organization_id?.id || 0
        }
        if (value.organization_type.id === 2) {
          this.form.shipper_id = {id: value.id, text: value.name}
        }
        else {
          this.fields[index].useSkeleton = true
          this.$store.dispatch('fetchService', { name: 'getShippersByOrganizationSimplifiedQueryParams', queryParams, params, onSuccess: (resp) =>  {
            this.fields[index].useSkeleton = false
            if (!!this.filterPudosByQuery && this.$route.query?.seller_id) {
              const seller = resp.data.filter(el => el.id === Number(this.$route.query?.seller_id))[0]          
              if (!!seller) {
                seller.text = seller.name
                this.form.shipper_id = seller
              }
            }
          }})
        }
      } else {
        this.form.country = null
        this.fields[index].containerClass = 'hide'
        this.form.shipper_id = null
        this.form.organization_id = null
        this.keyFormRender++
        this.changeCountry('country', this.form.country)
        this.disabledFieldCountry(false)
      }   
      
    },
    getOrganization (value, index) {
      this.disabledFieldCountry(true)
      this.baseService.callService('getOrganization', null, { id: value.id })
        .then(resp => {
          const resultCountry = resp.data.country
          if (this.form.country?.id !== resultCountry.id) {
            this.form.country = resultCountry
            this.changeCountry('country', this.form.country)
          }   
        })
        .catch(err => {
          console.error(err)
        })
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    changeCountry (name, value, notForceClean, changeForce) {
      if (!notForceClean) {
        this.form.level1_pudo = undefined
        this.form.level2_pudo = undefined
        this.form.level3_pudo = undefined
        this.form.level4_pudo = undefined
      }
      this.fields = this.fields.filter(el => !el.name.includes('level') && !el.name.includes('skeleton'))
      this.country = value
      this.form = {
        ...this.form,
        country: value
      }
      //limpia todo
      if (!value?.id) return null
      //Guardo los campos normales
      let indexFromCountry = this.fields.findIndex(el => el.name === name)

      indexFromCountry++
      const display = this.internalLevels[this.country.code.toLowerCase()].display

      display.map(el => {
        this.fields.push({ name: `skeleton${el}`, useSkeleton: true, containerClass: 'col-sm-12 col-md-12 container-info' })
      })

      this.fields.splice(indexFromCountry + 1, 0, this.fieldSkipLine)
      //Pego los campos originales
      if (value?.id) {
        this.nextLevelData = {}
        if (['mx'].includes(value.code.toLowerCase())) {
          this.nextLevelData.level = 1
        }
        this.getLevels(value, false, changeForce)
      }
      
    },
    getLevels (country, forceGetLevels, changeForce) {
      const params = {country: country.code.toLowerCase() }
      const { queryParams, onSuccessFx } = this.getQueryParamLevel(country.code.toLowerCase(), changeForce)
      if (!['mx'].includes(params.country) || forceGetLevels) {
        this.$store.dispatch('fetchService', { name: 'getLevels', params, queryParams, onSuccess: (data1, data2) => {
          onSuccessFx(data1, data2)
          if (!!this.forceSetMxLevel4) {
            this.changePlaceLevel('level4_pudo', null, 'undefined_pudo', true); this.forceSetMxLevel4 = false
          }
        }})
      } else {
        this.$store.commit('setLevels', [])
        this.loadedLevels('getLevels', changeForce)
      }
    },
    getQueryParamLevel (country_code, changeForce) {
      let queryParams = {}
      let onSuccessFx = () => this.loadedLevels('getQueryParamLevel', changeForce)
      if (['co', 'mx', 'pe'].includes(country_code)) {
        queryParams = {...this.nextLevelData}
        delete queryParams.next
        if (this.nextLevelData.next) {
          onSuccessFx = (data) => {
            this.setManualOptions(data.data.level1, this.nextLevelData.next)
          }
        }
      }
      return { queryParams, onSuccessFx }
    },
    searchLevels (name, value) {
      const level = name.split('_')[0].replace('level', '')
      const queryParams = {
        level,
        name: value
      }
      const params = {
        country: this.form.country.code.toLowerCase()
      }
      if (this.form[name.replace(level, level + 1)]) {
        queryParams.parent_id = this.form[name.replace(level, level + 1)].id
      }
      this.$store.dispatch('fetchService', 
        { name: 'getLevels', params, queryParams, 
          onSuccess: (data) => this.setManualOptions(data.data?.level1 || [], name), hideAlert: true}
      )
    },
    loadedLevels (origin, changeForce) {
      const full_places = this.$generateArrayPlacesFromTree(this.levels)
      this.country = this.isAdmin ? this.form.country : this.sessionUser.country 
      this.fields = this.fields.filter(el => el.name.indexOf('skeleton') === -1).filter(el => !el.name.includes('level'))
      const indexSplice = this.fields.findIndex(el => el.name === 'country') + 1
      // const required = !!this.isAdmin ? this.internalLevels[this.country.code.toLowerCase()].required : [0]
      const country = {
        code: this.country.code.toLowerCase(),
        requiredLevels: [0],
        displayLevels: this.internalLevels[this.country.code.toLowerCase()].display,
        maxLevel: Math.max(...this.internalLevels[this.country.code.toLowerCase()].required),
        levels: full_places,
        loadLevelsOnSelection: false,
        stepByStep: ['co', 'mx', 'pe'].includes(this.country.code.toLowerCase()),
        level1Search: this.searchLevels
      }
      const fieldOrigin = {
        fieldType: 'FieldsLevels',
        name: 'levels',
        extraName: 'pudo',
        country,
        dependency: 'country',
        change: this.changePlaceLevel,
        containerClass: 'col-sm-12 col-md-12 container-info'
      }
      this.fields.splice(indexSplice, 0, this.fieldSkipLine, fieldOrigin, this.fieldSkipLine)
      if (!!changeForce) {
        const country = this.internalLevels[this.country.code.toLowerCase()].display
        const last = country[country.length - 1] 
        if (['co', 'pe'].includes(this.country.code.toLowerCase())) {
          this.changePlaceLevel(`level${last}_pudo`, null, 'undefined_pudo', true)
        }
        if (['mx'].includes(this.country.code.toLowerCase())) {
          if (this.form.level2_pudo) this.forceSetMxLevel4 = true
          this.changePlaceLevel('level1_pudo', this.form.level1_pudo, 'level2_pudo', true, true, true)
        }
      }

    },
    changePlaceLevel (name, value, next, notSetForm) {
      if (!notSetForm) this.form = {...this.form, [next]: undefined}
      const countryCode = this.form.country.code.toLowerCase()
      const mx = ['mx'].includes(countryCode)
      this.requireLastLevel(countryCode, mx, next)
      const level = name
      if (['co', 'mx', 'pe'].includes(countryCode)) {
        // Prueba para el salto de level en mx
        const currentLevel = (name === 'level2_pudo') && mx ? 3 : +name.split('_')[0].replace('level', '')
        this.nextLevelData = {
          level: this.internalLevels[countryCode].required.length === currentLevel ? currentLevel : currentLevel + 1,
          parent_id: this.internalLevels[countryCode].required.length === currentLevel ? this.form[level.replace(`level${currentLevel}`, `level${currentLevel > 1 && currentLevel === 4 ? currentLevel - 2 : currentLevel > 1 && currentLevel !== 4 ? currentLevel - 1 : currentLevel}`)].id : value.id,
          next: this.internalLevels[countryCode].required.length === currentLevel ? name : next
        }
        // Prueba para el cambio de queryParams en mx en el caso especial de saltar un nivel
        if ((name === 'level2_pudo' || name === 'level4_pudo') && mx) {
          this.nextLevelData.grandparent_id = this.nextLevelData.parent_id
          delete this.nextLevelData.parent_id
        }
      }
      this.getLevels(this.form.country, true)

      
    },
    setManualOptions (places, fieldTo) {
      const full_places = this.$generateArrayPlacesFromTree(places)
      const level_num = +fieldTo.split('_')[0].replace('level', '')
      this.$refs.formRenderFilter.setManualOptions(fieldTo, full_places.filter(place => place.level_num === level_num))
    },
    // Es necesario para requirir el ultimo nivel solo en el caso que se realice un change en el primero
    // Cuando ya se pueda filtrar por cualquier level no deberia ser necesario
    requireLastLevel (countryCode, mx, next) {
      const penultimate = this.internalLevels[countryCode].display.slice(-2, -1)
      const last = this.internalLevels[countryCode].display[this.internalLevels[countryCode].display.length - 1]
      const indexLevel = this.fields.findIndex(el => el.name === 'levels')
      const fieldLevelRequired = this.fields[indexLevel].country.requiredLevels
      // Requerir ultimo nivel
      if (!!this.form[`level${penultimate}_pudo`] || (!!this.form.level1_pudo && mx)) {
        if (fieldLevelRequired[0] === 0) {
          this.setRequiredLevels(indexLevel, last)
        }
      }
      // Eliminar requerido del ultimo nivel
      if ((!mx && next === 'null_pudo') || (mx && !this.form.level1_pudo)) {
        if (fieldLevelRequired[0] !== 0) {
          this.setRequiredLevels(indexLevel, 0)
        } 
      }
    },
    setRequiredLevels (indexLevel, number) {
      this.fields[indexLevel].country.requiredLevels = [number]
      this.keyFormRender++
    },
    setCountryByCarrier () {
      this.countryCarrier = this.formattedCountry.filter(item => item.code.toLowerCase() === this.form.courier.country.code.toLowerCase())
      this.disabledFieldCountry(true)
      this.form.country = this.countryCarrier[0]
      this.changeCountry('country', this.form.country)
    },
    changeCourier (name, value) {
      if (this.isAdmin) {
        if (!!value) {
          if (this.form.country === undefined || (this.form.courier.country?.id !== this.countryCarrier[0].id)) this.setCountryByCarrier()
        }
        else {
          this.form.country = undefined
          this.changeCountry('country', this.form.country)
          this.disabledFieldCountry(false)
        }
      }
    },
    disabledFieldCountry (disabled) {
      this.indexCountry = this.fields.findIndex(el => el.name === 'country')
      this.fields[this.indexCountry].disabled = disabled
    },
    cleanForm() {
      this.form = {}
      this.keyFormRender++
    }
  }
 
}
</script>

<style>
.modal-body{
    padding: 2.8rem 4.4rem !important;
}
.content-img {
    margin-top: 1rem;
}
.content-img img {
    width: 100%;
}
h5 {
    font-size: 1.5rem;
    font-weight: 600;
}
.main-box-autocomplete {
  z-index: 1000 !important;
}
.hide {
  display: none;
}
.vc-popover-content-wrapper{
  z-index: 1000 !important;
}
</style>